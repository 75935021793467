import { CircularProgress, Stack, Typography, styled } from "@mui/material";
import React, { FC, ReactNode, useRef } from "react";

interface InfiniteScrollProps {
  dataLength: number | undefined;
  hasMore: boolean | null | undefined;
  loading: boolean;
  children: ReactNode;
  endMessage?: ReactNode;
  emptyMessage?: ReactNode;
  fetchMoreData: () => void;
}

const InfiniteScroll: FC<InfiniteScrollProps> = ({
  dataLength,
  loading,
  hasMore,
  children,
  endMessage,
  emptyMessage,
  fetchMoreData,
}) => {
  const observer = useRef<IntersectionObserver>();

  const lastElement = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, fetchMoreData]
  );

  return (
    <PostsContainer>
      {children}
      {hasMore ? (
        <div ref={lastElement} style={{ height: "2px", width: "100%" }} />
      ) : null}
      {loading ? <Loader /> : !hasMore && dataLength ? endMessage : null}
      {!loading && !dataLength
        ? emptyMessage || <Empty title="No posts" />
        : null}
    </PostsContainer>
  );
};

export const Loader = () => (
  <Stack justifyContent="center" alignItems="center" padding={1.5}>
    <CircularProgress size={24} />
  </Stack>
);

export const Empty: FC<{ title: string }> = ({ title }) => (
  <Stack justifyContent="center" alignItems="center" padding={1.5}>
    <Typography>{title}</Typography>
  </Stack>
);

export default InfiniteScroll;

const PostsContainer = styled("div")`
  max-width: 550px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;
