import { IconButton, IconButtonProps, Paper } from "@mui/material";
import React, { FC, memo } from "react";
import styled from "@emotion/styled";
import ReactPlayer from "react-player/lazy";
import { Close } from "@mui/icons-material";
import { Maybe, Media } from "api";

interface VideoProps extends IconButtonProps {
  video: File | Maybe<Media> | undefined;
}

function isFile(input) {
  if ('File' in window && input instanceof File)
    return true;
  else return false;
}

export const Video: FC<VideoProps> = memo(({ video, ...others }) => {

  const src = isFile(video) ? URL.createObjectURL(video as any) : (video as any)?.uri?.showUri

  return (
    <Container variant="outlined">
      <IconButton {...others}>
        <Close />
      </IconButton>
      {Boolean(video) && <ReactPlayer
        width="100%"
        url={src}
        controls
      />}
    </Container>
  )
});

const Container = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 15px;
  & > div {
    height: 272px !important;
  }
  .MuiIconButton-root {
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 8px;
    z-index: 1;
    background-color: #2196f3;
    &:hover, &:active , &:focus {
        background-color: #2196f3;
    }
    color: #ffffff;
  }
`;