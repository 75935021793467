import React, { FC, Fragment, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { CustomRoute } from "components/route";

// layouts
import MainLayout from "layouts/MainLayout";
import { NotificationCountProvider } from "providers";
import SigninRoute from "components/route/SigninRoute";
import FilterPosts from "pages/FilterPosts/FilterPosts";

// pages
const Home = lazy(() => import("pages/Home"));
const Watchlist = lazy(() => import("pages/Watchlist"));
const Search = lazy(() => import("pages/Search"));
const Bookmarks = lazy(() => import("pages/Bookmark"));
const Notifications = lazy(() => import("pages/notifications"));
const Profile = lazy(() => import("pages/profile"));
const Club = lazy(() => import("pages/club"));
const Hashtag = lazy(() => import("pages/Hashtag/Hashtag"));
const Dollar = lazy(() => import("pages/Dollar"));
const PostDetails = lazy(() => import("pages/PostDetails"));
const SharedPost = lazy(() => import("pages/shared-post"));
const Feedback = lazy(() => import("pages/Feedback"));
const TermsAndConditions = lazy(() => import("pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyAndPolicy"));
const Help = lazy(() => import("pages/Help"));
const PublicAnalyst = lazy(() => import("pages/public-analyst"));
const Ticket = lazy(() => import("pages/Feedback/Ticket"));
const RaiseNewIssue = lazy(() => import("pages/Feedback/RaiseNewIssue"));
const HelpCentre = lazy(() => import("pages/HelpCentre"));
const Learn = lazy(() => import("pages/Learn"));

const OldPostRedirect = lazy(() => import("pages/OldPostRedirect"));
const OldStockRedirect = lazy(() => import("pages/OldStockRedirect"));
const Settings = lazy(() => import("../pages/settings"));
const SettingsAndSupport = lazy(() => import("../pages/SettingsSupport"));
const LandingPage = lazy(() => import("../pages/LandingPage"));
const LogoutOIDC = lazy(() => import("pages/LogoutOIDC"));
const AuthCallback = lazy(() => import("pages/AuthCallback"));
const CompleteProfile = lazy(() => import("pages/CompleteProfile"));
const DownloadLink = lazy(() => import("../pages/DownloadLink"));
const Plans = lazy(() => import("pages/LandingPage/Plans"));
const Clubs = lazy(() => import("pages/clubs"));
const OnboardingPage = lazy(() => import("pages/OnboardingPage"));
const Discover = lazy(() => import("pages/Discover"));
const StockEdgeAnalysts = lazy(() => import("pages/Discover/StockEdgeAnalysts"));
const MarketExperts = lazy(() => import("pages/Discover/MarketExperts"));
const DomainExperts = lazy(() => import("pages/Discover/DomainExperts"));
const EngagingMembers = lazy(() => import("pages/Discover/EngagingMembers"));
const TrendingStocks = lazy(() => import("pages/Discover/TrendingStocks"));
const TrendingSector = lazy(() => import("pages/Discover/TrendingSector"));
const TrendingTopics = lazy(() => import("pages/Discover/TrendingTopics"));

const ClubCategory = lazy(() => import("pages/ClubCategory"));
// Past Posts
// const ProfileOldPosts = lazy(() => import("pages/profile/Post/OldPosts"));
const TopicOldPosts = lazy(() => import("pages/Hashtag/posts/OldPosts"));
const DollarTagOldPosts = lazy(() => import("pages/Dollar/posts/OldPosts"));

const Routes: FC = () => {
  return (
    <NotificationCountProvider>
      <Switch>
        <CustomRoute authenticated path="/watchlist">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Watchlist />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/search">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Search />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/bookmarks">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Bookmarks />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/explore">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Discover />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/stockedge-analysts">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <StockEdgeAnalysts />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/market-experts">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <MarketExperts />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/domain-experts">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <DomainExperts />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/engaging-members">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <EngagingMembers/>
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/trending-stocks">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <TrendingStocks />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/trending-sectors">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <TrendingSector />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/trending-topics">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <TrendingTopics />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/notifications">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Notifications />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/learn">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Learn />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/profile/:username">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Profile />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/club/:clubId">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Club />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/category/:clubCategoryId">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <ClubCategory />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/hashtag/:hashtag/old-posts">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <TopicOldPosts />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/hashtag/:hashtag">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Hashtag />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/stock/:slug/:dollarTagId/old-posts">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <DollarTagOldPosts />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/stock/:slug/:dollarTagId">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Dollar />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/sector/:slug/:dollarTagId/old-posts">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <DollarTagOldPosts />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/sector/:slug/:dollarTagId">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Dollar />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute
          authenticated
          path="/industry/:slug/:dollarTagId/old-posts"
        >
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <DollarTagOldPosts />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/industry/:slug/:dollarTagId">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Dollar />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/stocktag/:slug/:securityId">
          <Suspense fallback={<Fragment />}>
            <OldStockRedirect />
          </Suspense>
        </CustomRoute>
        <CustomRoute authenticated path="/posts/:postId/shares">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <SharedPost />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/posts/:postId">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <PostDetails />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/old-post/:oldPostId">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <OldPostRedirect />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/clubs">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Clubs />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/home">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Home />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <Route path="/locallogout">
          <Suspense fallback={<Fragment />}>
            <LogoutOIDC />
          </Suspense>
        </Route>
        <CustomRoute authenticated path="/settings-support">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <SettingsAndSupport />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/settings">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Settings />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/help-centre">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <HelpCentre />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/feedback/create">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <RaiseNewIssue />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/feedback/:ticketId">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Ticket />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/feedback">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <Feedback />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <CustomRoute authenticated path="/filter-posts">
          <MainLayout>
            <Suspense fallback={<Fragment />}>
              <FilterPosts />
            </Suspense>
          </MainLayout>
        </CustomRoute>
        <Route path="/onboarding">
          <Suspense fallback={<Fragment />}>
            <OnboardingPage />
          </Suspense>
        </Route>
        <Route path="/plans">
          <Suspense fallback={<Fragment />}>
            <Plans />
          </Suspense>
        </Route>
        <Route path="/analyst/:username">
          <Suspense fallback={<Fragment />}>
            <PublicAnalyst />
          </Suspense>
        </Route>
        <Route path="/terms">
          <Suspense fallback={<Fragment />}>
            <TermsAndConditions />
          </Suspense>
        </Route>
        <Route path="/privacy">
          <Suspense fallback={<Fragment />}>
            <PrivacyPolicy />
          </Suspense>
        </Route>
        <Route path="/migrations">
          <Suspense fallback={<Fragment />}>
            <Help />
          </Suspense>
        </Route>
        <Route path="/download">
          <Suspense fallback={<Fragment />}>
            <DownloadLink />
          </Suspense>
        </Route>
        <Route path="/auth/callback">
          <Suspense fallback={<Fragment />}>
            <AuthCallback />
          </Suspense>
        </Route>
        <Route path="/auth/complete-profile">
          <Suspense fallback={<Fragment />}>
            <CompleteProfile />
          </Suspense>
        </Route>
        <SigninRoute path="/">
          <Suspense fallback={<Fragment />}>
            <LandingPage />
          </Suspense>
        </SigninRoute>
      </Switch>
    </NotificationCountProvider>
  );
};

export default Routes;
