import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  FormControlLabel,
  IconButton,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, Fragment, useCallback, useState } from "react";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import {
  useGetReportTypesQuery,
  useCreateReportMutation,
  ReportType,
  Maybe,
  ReportedOn,
} from "../../api";
import styled from "styled-components";

interface CommentReportProps {
  handleCloseModal: () => void;
  postId: string | undefined;
  commentId: string | undefined;
}

export const CommentReport: FC<CommentReportProps> = ({
  handleCloseModal,
  postId,
  commentId
}) => {
  const { data } = useGetReportTypesQuery();
  const [createReport] = useCreateReportMutation();
  const [reportType, setReportType] = useState<Maybe<ReportType>>(null);
  const [remark, setRemark] = useState<string>("");

  const handleSubmit = useCallback(() => {
    // createReport()
    console.log("Handle Submit");
    if (reportType) {
      createReport({
        variables: {
          data: {
            reportType,
            reportedOn: ReportedOn.Comment,
            postId,
            commentId,
            remark,
          },
        },
      }).then((d) => {
        toast.success(`Successfully reported`);
        handleCloseModal();
      });
    }
  }, [createReport, reportType, remark, postId, commentId, handleCloseModal]);

  return (
    <Fragment>
      <DialogTitle>
        <Typography>Report on Comment</Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Heading>If you find any issue with this post, please feel free to report your concern.</Heading>
        <SubHeading>Please let us know why you want to report this?</SubHeading>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={reportType}
          onChange={(event: any) => {
            setReportType(event.target.value);
          }}
        >
          {data?.getReportTypes?.map((d) => {
            return (
              <FormControlLabel
                key={d}
                value={d}
                control={<Radio />}
                label={d}
              />
            );
          })}
        </RadioGroup>
        <TextField
          id="outlined-full-width"
          label="Add your remark"
          placeholder="Write something to help us better understand your concern"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          multiline
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          onClick={handleCloseModal}
          className="btnCancel"
        >
          Cancel
        </Button>
        <Button
          fullWidth
          color="primary"
          autoFocus
          onClick={handleSubmit}
          disabled={!reportType}
        >
          Submit
        </Button>
      </DialogActions>
    </Fragment>
  );
};

const Heading = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 10px;
  `;

const SubHeading = styled.div`
    font-size: 1rem;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 10px;
  `;
