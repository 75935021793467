import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  Fragment,
} from "react";
import { isEmpty } from "lodash-es";
import {
  PlatformTypeForInterstitial,
  useGetInterstitialsQuery,
  useGetFeedByTypeQuery,
} from "api";
import NewPostPrompt from "./NewPostPrompt";
import SmartFeed from "features/smart-feed";
import { isMobile } from "react-device-detect";
import InfiniteScroll, { Empty } from "components/InfiniteScroll";
import PostComponent from "components/PostComponent";
import ActivityMessage from "components/PostComponent/ActivityMessage";

interface ActivitiesProps {
  feedType: string;
  showSmartFeed?: boolean | null;
}

const Activities = forwardRef<any, ActivitiesProps>(
  ({ feedType, showSmartFeed }, ref) => {
    const [hasMore, setHasMore] = useState(true);

    const { data, fetchMore, loading, refetch, networkStatus, error } =
      useGetFeedByTypeQuery({
        variables: { feedType, offset: 0, limit: 20 },
        notifyOnNetworkStatusChange: true,
      });

    const { data: interstitials } = useGetInterstitialsQuery({
      variables: {
        platformType: isMobile
          ? PlatformTypeForInterstitial.Mobile
          : PlatformTypeForInterstitial.Web,
      },
      skip: showSmartFeed == false,
    });
    useImperativeHandle(
      ref,
      () => {
        return {
          refetch() {
            if (!loading) {
              refetch();
            }
          },
        };
      },
      [loading]
    );

    const fetchMoreData = () => {
      fetchMore({
        variables: { offset: data?.getFeedByType?.length },
      }).then(({ data }) => {
        if (isEmpty(data?.getFeedByType || [])) {
          setHasMore(false);
        }
      });
    };

    const interstitialsObj = (interstitials?.getInterstitials || []).reduce(
      (a, c) => ({ ...a, [c?.position as number]: c }),
      {}
    );

    return (
      <InfiniteScroll
        dataLength={data?.getFeedByType?.length}
        loading={loading}
        hasMore={hasMore}
        fetchMoreData={fetchMoreData}
        endMessage={<Empty title="No more posts" />}
      >
        {data?.getFeedByType?.length ? (
          <NewPostPrompt
            refetch={refetch}
            date={data?.getFeedByType?.[0]?.createdAt}
            feedType={feedType}
          />
        ) : null}
        {data?.getFeedByType?.map((activity, key) => (
          <Fragment key={activity?.actId}>
            {interstitialsObj?.[key] ? (
              <SmartFeed data={interstitialsObj?.[key]} />
            ) : null}
            <PostComponent
              postHeaderComponent={<ActivityMessage activity={activity} />}
              post={activity?.post}
              component_name={feedType}
            />
          </Fragment>
        ))}
      </InfiniteScroll>
    );
  }
);

export default Activities;
