import React, {
  FC,
  Fragment,
  SyntheticEvent,
  useCallback,
  useState,
  Dispatch,
  useRef,
  useEffect,
} from "react";
import {
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  DialogActions,
  CircularProgress,
  Divider,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";
import {
  FeedPostsDocument,
  ClubPostDocument,
  Maybe,
  MediaInput,
  useSharePostV3Mutation,
  PostInput as PostInputData
} from "api";
import { Action, State } from "./reducer";
import { useSharePost } from "./useSharePost";
import MentionsInput from "components/MentionsInput";
import EmojiPicker from "../EmojiPicker";
import { calculateCursorPosition } from "helper";
import { UserDisplay } from "./UserDisplay";
import SharedPostComponent from "../PostComponent/SharedPostComponent";
import SharePostDropdowm from "./SharePostDropdowm";
import { toast } from "react-hot-toast";
import { InternalRefetchQueriesInclude } from "@apollo/client";
import { COLORS } from "assets/colors";
import { useClubPermission } from "hooks";

interface Props {
  state: State;
  dispatch: Dispatch<Action>;
  handleClose: () => void;
}

export const PostInput: FC<Props> = ({ handleClose }) => {
  const history = useHistory();
  const { ref } = useResizeDetector();
  const { post, handleCloseModal } = useSharePost();
  const mentionInputRef = useRef<HTMLTextAreaElement>(null);
  const [cursorPosition, setCursorPosition] = useState<any>();
  const [text, setText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [clubId, setClubId] = useState<string | undefined>(undefined);
  const { token } = useClubPermission(post?.Club?.clubId);

  const isClubPost = Boolean(post?.Club);

  const [sharePost] = useSharePostV3Mutation({
    refetchQueries: [
      { query: FeedPostsDocument, variables: { offset: 0, limit: 20 } },
    ],
  });

  useEffect(() => {
    if (isClubPost) {
      setClubId(post?.Club?.clubId);
    }
  }, [isClubPost, setClubId]);

  useEffect(() => {
    function onpress(this: Document, ev: KeyboardEvent) {
      const helps: Record<string, string> = {
        Enter: "\n",
        NumpadEnter: "\n",
        Space: " ",
      };
      if (["Enter", "NumpadEnter", "Space"].includes(ev.code)) {
        const newText = text.replace(
          /(^|\s)(#)([a-z\d-]+)/gi,
          "$1[$2$3:$3]" + helps[ev.code]
        );
        setText(newText);
      }
    }
    document.addEventListener("keypress", onpress);
    return () => {
      document.removeEventListener("keypress", onpress);
    };
  }, [mentionInputRef, text, setText]);

  const addEmoji = useCallback((emoji: any) => {
    if (mentionInputRef.current && "native" in emoji) {
      const {
        selectionStart: startPos,
        selectionEnd: endPos,
        value: txt,
      } = mentionInputRef.current;
      if (startPos === undefined || endPos === undefined) return;
      const cursorPosition = calculateCursorPosition(startPos, txt, text);
      const result =
        text.substring(0, cursorPosition + 1) +
        emoji.native +
        text.substring(cursorPosition + 1, text.length);
      mentionInputRef.current.value = result;
      setCursorPosition(startPos + emoji.native.length);
      setText(result);
    }
  },
    [mentionInputRef, setText, text, setCursorPosition]
  );

  useEffect(() => {
    if (mentionInputRef.current && cursorPosition) {
      mentionInputRef.current.selectionStart = cursorPosition;
      mentionInputRef.current.selectionEnd = cursorPosition;
      mentionInputRef.current.focus();
    }
  }, [mentionInputRef.current, cursorPosition]);

  const handleCreatePost = async (event: SyntheticEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      const media: Array<Maybe<MediaInput>> = [];
      const refetchQueries: InternalRefetchQueriesInclude = [{
        query: FeedPostsDocument,
        variables: { offset: 0, limit: 20 }
      }];

      const newdata: PostInputData = { text, media, sharePostId: post?.postId }
      if (clubId) {
        newdata.clubId = clubId;
        newdata.clubBasedPermissionToken = token;
        refetchQueries.push({
          query: ClubPostDocument,
          variables: { clubId, offset: 0, limit: 20 }
        })

      }
      const { data } = await sharePost({
        variables: { data: newdata },
        refetchQueries,
        awaitRefetchQueries: true
      });
      const link = `/posts/${data?.sharePostV3?.postId}`;
      const msg = clubId ? "Shared to Club." : "Shared to Feed.";
      setText("");
      handleCloseModal();

      toast.success((t) => (
        <ToastDiv>
          {msg}
          <a href={link} target="__blank">
            View post
          </a>
          <IconButton size="small" onClick={() => toast.dismiss(t.id)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </ToastDiv>
      ));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <Loader>
          <CircularProgress />
        </Loader>
      )}
      <DialogTitle>
        <Typography>Share Post</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        ref={ref}
        onClick={() => {
          if (mentionInputRef.current) {
            mentionInputRef.current.focus();
          }
        }}
      >
        <UserDisplay>
          {isClubPost ? (
            "  " + post?.Club?.clubName
          ) : (
            <SharePostDropdowm clubId={clubId} setClubId={setClubId} />
          )}
        </UserDisplay>
        <MentionsInput
          inputRef={mentionInputRef}
          value={text}
          onChange={(_, text) => setText(text)}
          placeholder="Write your post here..."
          suggestionsPortalHost={ref.current}
        />
        <SharedPostComponent post={post} disabled/>
      </DialogContent>
      <DialogActions>
        <EmojiPicker onEmojiSelect={addEmoji} />
        <CustomButton color="primary" variant="contained" onClick={handleCreatePost}>
          Share
        </CustomButton>
      </DialogActions>
    </Fragment>
  );
};



const CustomButton = styled(Button)`
background-color:${COLORS.buttonActive};
color:${COLORS.buttonTextActive};
  &:hover {
    background-color:${COLORS.buttonActive};
    color: ${COLORS.buttonTextActive};
  }
`;
const ToastDiv = styled("div")`
  a {
    color: #2196f3;
    margin-left: 12px;
    text-decoration: none;
  }
  .MuiIconButton-root {
    color: #3a3939;
    margin-left: 4px;
    margin-right: -12px;
  }
`;
const Loader = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 4px;
  .MuiCircularProgress-colorPrimary {
    color: var(--button-color);
    opacity: 0.65;
  }
`;
