import React, { FC } from "react";
import { debounce } from "lodash-es";
import { Avatar as MuiAvatar, Typography, styled } from "@mui/material";
import {
  MentionsInput,
  MentionsInputProps,
  Mention,
  MentionProps,
  SuggestionDataItem,
  DataFunc,
} from "react-mentions";
import {
  DollarTagEnum,
  GetUserListDocument,
  GetUserListQuery,
  GetUserListQueryVariables,
  GetHashtagBySearchTextDocument,
  GetHashtagBySearchTextQuery,
  GetHashtagBySearchTextQueryVariables,
  GetDollartagBySearchTextDocument,
  GetDollartagBySearchTextQuery,
  GetDollartagBySearchTextQueryVariables,
  Maybe,
} from "api";
import { colorMap } from "constants/index";
import { useApolloClient } from "@apollo/client";
import AAvatar from "components/Avatar";
import { getIsRingVisible } from "utills";

const CustomMentionsInput1 =
  MentionsInput as unknown as React.ComponentClass<MentionsInputProps>;

const CustomMention = Mention as unknown as React.ComponentClass<MentionProps>;

export const CustomMentionsInput: FC<Omit<MentionsInputProps, "children">> = (
  props
) => {
  const client = useApolloClient();

  const getUsers: DataFunc = debounce((searchText, callback) => {
    console.log("Mention Users");
    client
      .query<GetUserListQuery, GetUserListQueryVariables>({
        query: GetUserListDocument,
        variables: { data: { searchText, skip: 0, take: 50 } },
      })
      .then(({ data }) =>
        data?.getUserList?.map((user) => ({
          display: `${user?.firstName} ${user?.lastName}`,
          id: `${user?.username}/${user?.id}`,
          avatar: user?.avatar?.LowResUri,
          membershipLevel: user?.membershipLevel,
          isVerified: user?.isVerified,
          greenTick: user?.greenTick,
          tick3: user?.tick3,
        }))
      )
      .then(callback);
  });

  const getHashTags: DataFunc = debounce((searchText, callback) => {
    console.log("Mention Hashtag");
    client
      .query<GetHashtagBySearchTextQuery, GetHashtagBySearchTextQueryVariables>(
        {
          query: GetHashtagBySearchTextDocument,
          variables: { data: { searchText, skip: 0, take: 50 } },
        }
      )
      .then(({ data }) => {
        const hastags: Array<any> = data?.getHashtagBySearchText?.map(
          (item) => ({
            display: item?.Hashtag?.hashtagName,
            id: item?.Hashtag?.hashtagName,
          })
        );
        if (window.screen.width <= 540) {
          const isAlreadyCreated = hastags.find((d) => d.id === searchText);
          if (!isAlreadyCreated && searchText.trim().length >= 3) {
            hastags.unshift({
              display: searchText.trim(),
              id: searchText.trim().toLowerCase(),
            });
          }
        }
        return hastags;
      })
      .then(callback);
  });

  const getStocks: DataFunc = debounce((searchText, callback) => {
    console.log("Mention Dollars");
    client
      .query<
        GetDollartagBySearchTextQuery,
        GetDollartagBySearchTextQueryVariables
      >({
        query: GetDollartagBySearchTextDocument,
        variables: { searchText, offset: 0, limit: 100 },
      })
      .then(({ data }) =>
        data?.getDollartagBySearchText?.map((dollar) => ({
          display: dollar?.dollarTagName,
          id: `${dollar?.dollarTagType?.toLocaleLowerCase()}/${dollar?.slug}/${
            dollar?.dollarTagId
          }`,
          dollarTagType: dollar?.dollarTagType,
        }))
      )
      .then(callback);
  });

  return (
    <CustomMentionsInput1
      placeholder="Write your post here..."
      autoFocus
      className="mention"
      allowSpaceInQuery
      spellCheck="true"
      allowSuggestionsAboveCursor
      {...props}
    >
      <CustomMention
        trigger="@"
        data={getUsers}
        markup="[@__display__:__id__]"
        displayTransform={(_, display) => `@${display}`}
        renderSuggestion={(suggestion) => (
          <RenderUserSuggestion suggestion={suggestion} />
        )}
        appendSpaceOnAdd
        className="mention_user_mention"
      />
      <CustomMention
        trigger="#"
        data={getHashTags}
        markup="[#__display__:__id__]"
        displayTransform={(_, display) => `#${display}`}
        renderSuggestion={(suggestion) => (
          <RenderHastagSuggestion suggestion={suggestion} />
        )}
        appendSpaceOnAdd
        className="mention_hastag_mention"
      />
      <CustomMention
        trigger="$"
        data={getStocks}
        markup="[$__display__:__id__]"
        displayTransform={(_, display) => `$${display}`}
        renderSuggestion={(suggestion) => (
          <RenderStockSuggestion suggestion={suggestion} />
        )}
        appendSpaceOnAdd
        className="mention_stock_mention"
      />
    </CustomMentionsInput1>
  );
};

export default CustomMentionsInput;

const RenderUserSuggestion: FC<{
  suggestion: SuggestionDataItem & {
    avatar?: string;
    isVerified?: boolean;
    greenTick?: boolean;
    tick3?: boolean;
    membershipLevel?: Maybe<number> | undefined;
  };
}> = ({ suggestion }) => (
  <>
    <AAvatar
      src={suggestion.avatar}
      hasRing={getIsRingVisible(suggestion?.membershipLevel)}
      sx={{
        height: 30,
        width: 30,
        marginRight: 0.75,
      }}
    />
    <Typography
      variant="subtitle2"
      component="span"
      sx={{ marginLeft: 0.5, fontSize: "0.725rem !important" }}
    >
      {suggestion.display}
      {Boolean(suggestion?.isVerified) && (
        <TickImage
          src={require("assets/image/blue-check.webp")}
          alt="Blue Tick"
        />
      )}
      {Boolean(suggestion?.greenTick) && (
        <TickImage
          src={require("assets/image/green-check.webp")}
          alt="Green Tick"
        />
      )}
      {Boolean(suggestion?.tick3) && (
        <TickImage
          src={require("assets/image/yellow-tick.webp")}
          alt="Green Tick"
        />
      )}
    </Typography>
  </>
);

const RenderHastagSuggestion: FC<{ suggestion: SuggestionDataItem }> = ({
  suggestion,
}) => (
  <>
    <Avatar sx={{ color: "#000" }}>#</Avatar>
    <Typography
      variant="subtitle2"
      component="span"
      sx={{ marginLeft: 0.5, fontSize: "0.725rem !important" }}
    >
      {suggestion.display}
    </Typography>
  </>
);

const RenderStockSuggestion: FC<{
  suggestion: SuggestionDataItem & { dollarTagType?: DollarTagEnum };
}> = ({ suggestion }) => (
  <>
    <Avatar sx={{ color: "#000" }}>$</Avatar>
    <Typography
      variant="subtitle2"
      component="span"
      sx={{ marginLeft: 0.5, fontSize: "0.725rem !important" }}
    >
      {suggestion.display}
    </Typography>
    <Small color={colorMap[suggestion?.dollarTagType!]}>
      {suggestion.dollarTagType}
    </Small>
  </>
);

const TickImage = styled("img")`
  height: 12px;
  width: 12px;
  margin-left: 2px;
  margin-bottom: -2px;
`;

const Avatar = styled(MuiAvatar)`
  && {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
`;

const Small = styled("small")<{ color: string }>`
  display: flex;
  margin-left: auto;
  font-weight: bold;
  color: ${({ color }) => color || "#3ecf8e"};
  font-size: 10px;
  padding-left: 8px;
  padding-right: 8px;
`;
