import { Card, CardContent, Typography } from "@mui/material";
import { Maybe, Post, TagType } from "api";
import React, { forwardRef, ReactNode } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { PostBottomActions } from "./PostBottomActions";
import { PollComponent } from "./PollComponent";
import CommentComment, { CommentsContainer } from "components/CommentComponent";
import TextPost from "components/TextPost";
import PostDetailsMedia from "./PostDetailsMedia";
import PostDisclaimer from "components/PostDisclaimer";
import PostHeader from "./PostHeader";
import MediaPostNew from "./MediaPostNew";
import SharedPostComponent from "./SharedPostComponent";
import ImagePost from "./ImagePost";
import Pin from "./Pin";
import ImagePostNew from "./ImagePostNew";
import AvatarImage from "./AvatarImage";
import { PostImpressionComponentName, sendEvent } from "ga4";
import { useInView } from "react-intersection-observer";
import PostImpressionEventCall, {
  getUserTickStatus,
} from "components/PostImpressionEventCall";
import PostOffensiveContentText from "components/PostOffensiveContentText";

interface PostComponentProps {
  post: Maybe<Post> | undefined;
  children?: ReactNode;
  postHeaderComponent?: ReactNode;
  postCountComponent?: ReactNode;
  isPostDetails?: boolean;
  isProfilePosts?: boolean;
  isPinnedPost?: boolean;
  component_name?: PostImpressionComponentName;
}

const PostComponent = forwardRef<HTMLDivElement, PostComponentProps>(
  (
    {
      post,
      children,
      postHeaderComponent,
      postCountComponent,
      isPostDetails = false,
      isProfilePosts = false,
      isPinnedPost = false,
      component_name,
    },
    ref
  ) => {
    const [inViewRef, inView] = useInView({
      threshold: 0.75,
    });

    const onMentionedTagTypeClick = (
      tagType: TagType,
      paramsLower?: string
    ) => {
      if (tagType === TagType?.UserTag) {
        sendEvent({
          event: "user_button_clicked",
          component_name: "post_mention",
        });
      } else if (tagType === TagType?.HashTag) {
        sendEvent({
          event: "topic_button_clicked",
          component_name: "post_mention",
        });
      } else if (
        tagType === TagType?.DollarTag &&
        paramsLower?.includes("stock/")
      ) {
        sendEvent({
          event: "stock_button_clicked",
          component_name: "post_mention",
        });
      } else if (
        tagType === TagType?.DollarTag &&
        (paramsLower?.includes("sector/") || paramsLower?.includes("industry/"))
      ) {
        sendEvent({
          event: "sector_button_clicked",
          component_name: "post_mention",
        });
      }
    };

    return (
      <CustomCard variant="outlined" ref={inViewRef}>
        {inView ? (
          <PostImpressionEventCall
            postId={post?.postId}
            component_name={component_name as PostImpressionComponentName}
            tick_status={getUserTickStatus(post?.User)}
          />
        ) : null}
        {isPinnedPost && <Pin />}
        {postHeaderComponent}
        <PostHeader
          post={post}
          isPostDetails={isPostDetails}
          isProfilePosts={isProfilePosts}
        />
        <PostOffensiveContentText post={post}>
          {isPostDetails ? (
            <CardContent>
              <TextPost
                text={post?.textV2}
                onMentionedTagTypeClick={onMentionedTagTypeClick}
              />
            </CardContent>
          ) : (
            <CardContent>
              <CustomLink
                to={`/posts/${post?.postId}`}
                aria-label="Read post details"
              >
                <TextPost
                  text={post?.textV2}
                  onMentionedTagTypeClick={onMentionedTagTypeClick}
                />
              </CustomLink>
            </CardContent>
          )}
          {isPostDetails ? (
            <>
              {post?.meta?.isAvatar ? (
                <AvatarImage media={post?.media} />
              ) : (
                <ImagePostNew media={post?.media!} />
              )}
              <PostDetailsMedia media={post?.media!} />
            </>
          ) : (
            <>
              {post?.meta?.isAvatar ? (
                <Link to={`/posts/${post?.postId}`}>
                  <AvatarImage media={post?.media} />
                </Link>
              ) : (
                <ImagePost media={post?.media} to={`/posts/${post?.postId}`} />
              )}
              <MediaPostNew post={post} isPostDetails={isPostDetails} />
            </>
          )}
          {post?.hasPoll && post?.getPoll && <PollComponent post={post} />}
          {Boolean(post?.sharePostId) && (
            <SharedPostComponent post={post?.getSharedPost} />
          )}
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {postCountComponent}
            <PostDisclaimer />
          </CardContent>
          <PostBottomActions
            post={post}
            isCommentExists={Boolean(post?.comments?.length) || isPostDetails}
          />
          {children}
          {Number(post?.comments?.length) > 0 && (
            <CommentsContainer>
              {post?.comments?.map((comment) => (
                <CommentComment
                  key={comment?.commentId}
                  post={post}
                  comment={comment}
                />
              ))}
              {post?.topCommentId && Number(post?.commentcount) > 1 ? (
                <Div>
                  <Typography
                    to={`/posts/${post?.postId}`}
                    aria-label="View all comments"
                    style={{ fontSize: "0.825rem" }}
                    component={Link}
                  >
                    View All Comments
                  </Typography>
                </Div>
              ) : null}
            </CommentsContainer>
          )}
        </PostOffensiveContentText>
      </CustomCard>
    );
  }
);

export default PostComponent;

const CustomLink = styled(Link)({
  textDecoration: "none",
});

const Div = styled("div")({
  textAlign: "center",
  "@media (min-width: 540px)": {
    textAlign: "left",
  },
});

const CustomCard = styled(Card)`
  margin-top: 16px;
  &:last-child {
    margin-bottom: 16px;
  }
  .MuiCardActions-root {
    .MuiButton-root {
      flex: 0 1 100%;
    }
  }
  @media (max-width: 350px) {
    margin-top: 0px;
  }
`;

export const PostsContainer = styled.div`
  max-width: 550px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;
