import React, { FC, Fragment, PropsWithChildren, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useAuthContext } from "context/AuthContext";
import FixedLoader from "components/FixedLoader";

const CustomRoute: FC<
  PropsWithChildren<RouteProps & { authenticated?: boolean }>
> = ({ authenticated = false, children, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const { isAuth } = useAuthContext();
  if (isLoading) return <FixedLoader />;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isAuthenticated) return <RedirectToOIDCLoginPage />;
        if (!isAuth)
          return (
            <Redirect
              to={{
                pathname: "/auth/callback",
                search: `?redirectTo=${encodeURIComponent(
                  location.pathname + location.search
                )}`,
              }}
            />
          );
        return <Fragment>{children}</Fragment>;
      }}
    />
  );
};

export default CustomRoute;

const RedirectToOIDCLoginPage = () => {
  const { signinRedirect } = useAuth();
  if (
    !location.pathname.includes("/locallogout") ||
    !location.pathname.includes("/auth") ||
    !location.pathname.includes("/onboarding")
  ) {
    sessionStorage.setItem("pathname", location.pathname);
  }
  useEffect(() => {
    signinRedirect();
  }, []);
  return null;
};
