import React, { FC } from "react";
import styled from "styled-components";
import { DialogTitle, IconButton, Typography, CardContent, Card, DialogContent, CardHeader, Divider } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Maybe, Post, useGetEditPostHistoryQuery } from "api";
import TextPost from "components/TextPost";
import PostHeaderAvatar from "components/PostComponent/PostHeaderAvatar";
import PostHeaderTitle from "components/PostComponent/PostHeaderTitle";
import ImagePost from "./ImagePost";
import PostDetailsMedia from "components/PostComponent/PostDetailsMedia";
import { PollComponent } from "components/PostComponent/PollComponent";
import PostHeaderSubTitle from "components/PostComponent/PostHeaderSubTitle";
import SharedPostComponent from "components/PostComponent/SharedPostComponent";

interface EditHistoryProps {
    post: Maybe<Post> | undefined;
    handleCloseModal: () => void;
}

const EditHistory: FC<EditHistoryProps> = ({ post, handleCloseModal }) => {

    const { data, loading } = useGetEditPostHistoryQuery({
        variables: { postId: post?.postId as string },
        fetchPolicy: "no-cache"
    });
    console.log("hvh", data);

    return (
        <>
            <DialogTitle>
                <Typography>Edit History</Typography>
                <IconButton onClick={handleCloseModal}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <PostsContainer>
                    {data?.getEditPostHistory?.map((item, key, arr) => {
                        return (
                            <>
                                <CustomCard variant="outlined" key={key}>
                                    <PostCardHeader
                                        avatar={<PostHeaderAvatar user={post?.User} />}
                                        title={<PostHeaderTitle user={post?.User} club={post?.Club} />}
                                        subheader={<PostHeaderSubTitle user={post?.User} createdAt={item?.editedAt} isEdited={false} />}
                                    />

                                    <CardContent>
                                        <TextPost text={item?.text} />
                                    </CardContent>
                                    <ImagePost media={item?.media!} />
                                    <PostDetailsMedia media={item?.media!} />
                                    {(post?.hasPoll && post?.getPoll) && <PollComponent post={post} />}
                                    {Boolean(post?.sharePostId) && <SharedPostComponent post={post?.getSharedPost} />}
                                </CustomCard>
                            </>
                        )
                    })}
                </PostsContainer>
            </DialogContent>
        </>
    );
};

export default EditHistory;

export const PostsContainer = styled.div`
    width: 100%;
padding: 5px;
`;

const PostCardHeader = styled(CardHeader)`
    padding: 12px 16px;
    margin-right: 4px;
    .MuiCardHeader-avatar {
        margin-right: 8px;
    }
`;
const CustomCard = styled(Card)`
    margin-top: 16px;
    padding: 5px;
    &:last-child {
        margin-bottom: 16px;
    }
    .MuiCardActions-root {
        .MuiButton-root {
            flex: 0 1 100%;
        }
    }
`;
const DateHeader = styled.div`
padding: 14px;
`;
