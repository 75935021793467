import React, { FC, Fragment, ReactNode, useState } from "react";
import styled from "@emotion/styled";
import {
  IconButton,
  CircularProgress,
  MenuItem,
  CardHeader,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import {
  Maybe,
  Post,
  PostFieldsFragmentDoc,
  useCreateBookmarkMutation,
  useDeletePostMutation,
  useRemoveBookmarkMutation,
  useMarkAsPinPostMutation,
  useUnPinPostMutation,
  GetPinnedPostDocument,
  GetPinnedPostQuery,
  PlatformPermissionFromFeatureTypes,
  useGetFeatureToPlatformPermissionQuery,
  usePostMoreMenuLazyQuery,
  ClubPermissionsBasedOnMemberType,
} from "api";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import toast from "react-hot-toast";
import { useConfirm } from "material-ui-confirm";
import { useAuthContext } from "context/AuthContext";
import { useReportModal } from "components/ReportModal";
import PostHeaderAvatar from "./PostHeaderAvatar";
import PostHeaderTitle from "./PostHeaderTitle";
import PostHeaderSubTitle from "./PostHeaderSubTitle";
import EditPostModal from "components/EditPost";
import EditPostHistoryModal from "components/EditPostHistory";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import MoreMenu from "components/MoreMenu";
import FollowPost from "./FollowPost";
import { useClubPermission, usePermissionFeatureMapping } from "hooks";
import { sendEvent } from "ga4";

interface PostHeaderProps {
  post: Maybe<Post> | undefined;
  isPostDetails?: boolean;
  isProfilePosts?: boolean;
}

const PostHeader: FC<PostHeaderProps> = ({
  post,
  isPostDetails = false,
  isProfilePosts = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenEditPostHistoryModal = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <PostCardHeader
        avatar={<PostHeaderAvatar user={post?.User} />}
        action={
          <PostHeaderAction
            post={post}
            isPostDetails={isPostDetails}
            isProfilePosts={isProfilePosts}
            handleOpenEditPostHistoryModal={handleOpenEditPostHistoryModal}
          />
        }
        title={<PostHeaderTitle user={post?.User} club={post?.Club} />}
        subheader={
          <PostHeaderSubTitle
            user={post?.User}
            createdAt={post?.createdAt}
            isEdited={post?.isEdited}
            handleOpenEditPostHistoryModal={handleOpenEditPostHistoryModal}
          />
        }
        disableTypography
      />
      <EditPostHistoryModal
        open={open}
        post={post}
        handleClose={handleCloseModal}
      />
    </Fragment>
  );
};

export default PostHeader;

const PostCardHeader = styled(CardHeader)`
  padding: 12px 16px;
  margin-right: 4px;
  .MuiCardHeader-avatar {
    margin-right: 8px;
  }
`;

interface PostHeaderActionProps {
  post: Maybe<Post> | undefined;
  isPostDetails: boolean;
  isProfilePosts: boolean;
  handleOpenEditPostHistoryModal: () => void;
}

const PostHeaderAction: FC<PostHeaderActionProps> = ({
  post,
  isPostDetails,
  isProfilePosts,
  handleOpenEditPostHistoryModal,
}) => {
  const { platformFeatureMap, clubFeatureMap } = usePermissionFeatureMapping();
  const [getPostMoreMenu, { data, loading }] = usePostMoreMenuLazyQuery();
  const history = useHistory();
  const confirm = useConfirm();
  const reportModal = useReportModal();
  const [deletePost] = useDeletePostMutation();
  const [addBookmark] = useCreateBookmarkMutation();
  const [removeBookmark] = useRemoveBookmarkMutation();
  const { handleModalOpen } = useUpgradePlanContext();
  const [pinPost] = useMarkAsPinPostMutation();
  const [unpinPost] = useUnPinPostMutation();
  const { user } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [modal, setModal] = useState<
    "EDIT_POST" | "EDIT_POST_HISTORY" | undefined
  >(undefined);
  const { data: platformPermission } = useGetFeatureToPlatformPermissionQuery();
  const { permissions } = useClubPermission(post?.Club?.clubId);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    getPostMoreMenu({ variables: { postId: post?.postId as string } });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnCopy = () => {
    document.body.style.removeProperty("overflow");
    navigator.clipboard.writeText(
      `${window.location.origin}/posts/${post?.postId}`
    );
    toast.success("Post link copied!");
    setAnchorEl(null);
  };

  const handleDeletePost = () => {
    document.body.style.removeProperty("overflow");
    setAnchorEl(null);
    confirm({
      description: "This action is permanent!",
      confirmationText: "Delete",
      confirmationButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
      dialogProps: {
        PaperProps: {
          style: {
            maxWidth: 400,
          },
        },
      },
    })
      .then(async () => {
        const variables = { data: { postId: post?.postId || "" } };
        await deletePost({
          variables,
          update: (cache, { data }) => {
            cache.evict({ id: cache.identify(post!) });
            toast.success("Successfully deleted!");
          },
        });
        if (isPostDetails) {
          history.replace("/home");
        }
      })
      .catch(() => {
        toast.error("Something error!");
      });
  };

  const handleAddBookmark = () => {
    document.body.style.removeProperty("overflow");
    setAnchorEl(null);
    addBookmark({
      variables: { data: { postId: post?.postId } },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getBookmarkV2(posts = []) {
              const newPost = cache.writeFragment({
                data: post,
                fragment: PostFieldsFragmentDoc,
                fragmentName: "PostFields",
              });
              return [newPost, ...posts];
            },
            getIsBookmarked: (refrence, { storeFieldName }) => {
              console.log(storeFieldName, refrence);
              if (
                storeFieldName ===
                `getIsBookmarked({"postId":"${post?.postId}"})`
              ) {
                return true;
              }
              return refrence;
            },
          },
        });
      },
    }).then(() => {
      toast.success("Bookmark added");
    });
  };

  const handleRemoveBookmark = () => {
    document.body.style.removeProperty("overflow");
    setAnchorEl(null);
    removeBookmark({
      variables: { data: { postId: post?.postId } },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getBookmarkV2(existingPosts = [], { readField }) {
              return existingPosts.filter(
                (o: any) => readField("postId", o) !== post?.postId
              );
            },
            getIsBookmarked: (refrence, { storeFieldName }) => {
              console.log(storeFieldName, refrence);
              if (
                storeFieldName ===
                `getIsBookmarked({"postId":"${post?.postId}"})`
              ) {
                return false;
              }
              return refrence;
            },
          },
        });
      },
    }).then(() => {
      toast.success("Bookmark removed");
    });
  };

  const handlePinOrUnpinPost = () => {
    document.body.style.removeProperty("overflow");
    setAnchorEl(null);
    if (post?.isPinned) {
      unpinPost({
        variables: { postId: post?.postId },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              getPinnedPost(existingPosts = [], { readField }) {
                return existingPosts.filter(
                  (o: any) => readField("postId", o) !== post?.postId
                );
              },
              getProfilePostsV2: (existingPosts = [], { readField }) => {
                const newPost = cache.writeFragment({
                  data: data?.unPinPost,
                  fragment: PostFieldsFragmentDoc,
                  fragmentName: "PostFields",
                });
                return [newPost, ...existingPosts];
              },
            },
          });
          toast.success("Your post has been unpinned.");
        },
      });
    } else {
      pinPost({
        variables: { postId: post?.postId },
        update: (cache, { data }) => {
          const d = cache.readQuery<GetPinnedPostQuery>({
            query: GetPinnedPostDocument,
            variables: { userId: post?.User?.id },
          });
          const posts = d?.getPinnedPost?.map((x) => ({
            ...x,
            isPinned: false,
          }));
          cache.modify({
            fields: {
              getPinnedPost() {
                const newPost = cache.writeFragment({
                  data: data?.markAsPinPost,
                  fragment: PostFieldsFragmentDoc,
                  fragmentName: "PostFields",
                });
                return [newPost];
              },
              getProfilePostsV2: (existingPosts = [], { readField }) => {
                return [
                  ...(posts || []),
                  ...existingPosts.filter(
                    (o: any) => readField("postId", o) !== post?.postId
                  ),
                ];
              },
            },
          });
          toast.success("Your post has been pinned.");
        },
      });
    }
  };

  const menus: Array<ReactNode> = [];
  const open = Boolean(anchorEl);
  const isMe = post?.User?.id === user?.id;

  if (!isPostDetails) {
    menus.push(
      <MenuItem
        component={Link}
        to={`/posts/${post?.postId}`}
        onClick={handleClose}
        disableRipple
        dense
      >
        Open post
      </MenuItem>
    );
  }
  menus.push(
    <MenuItem onClick={handleOnCopy} disableRipple dense>
      Copy post link
    </MenuItem>
  );
  menus.push(
    <MenuItem
      onClick={() => {
        if (
          !platformPermission?.getFeatureToPlatformPermission?.includes(
            PlatformPermissionFromFeatureTypes.CanReport
          )
        ) {
          sendEvent({
            event: "upgrade_modal_initialised",
            component_name: "post",
            feature_name_list:
              platformFeatureMap[PlatformPermissionFromFeatureTypes.CanReport],
          });
          handleModalOpen();
          return;
        }
        reportModal?.handleOpenModal(
          "POST",
          post?.postId!,
          undefined,
          undefined
        );
        handleClose();
      }}
      disabled={data?.isReported}
      disableRipple
      dense
    >
      {data?.isReported ? "Already reported" : "Report post"}
    </MenuItem>
  );
  if (data?.isBookmarked) {
    menus.push(
      <MenuItem onClick={handleRemoveBookmark} disableRipple dense>
        Remove bookmark
      </MenuItem>
    );
  } else {
    menus.push(
      <MenuItem onClick={handleAddBookmark} disableRipple dense>
        Add bookmark
      </MenuItem>
    );
  }

  if (isMe && isProfilePosts) {
    menus.push(
      <MenuItem onClick={handlePinOrUnpinPost} disableRipple dense>
        {post?.isPinned ? "Unpin Post" : "Pin Post"}
      </MenuItem>
    );
  }
  if (isMe && !(post?.meta?.isAvatar || post?.meta?.isCover)) {
    menus.push(
      <MenuItem
        disableRipple
        dense
        onClick={() => {
          document.body.style.removeProperty("overflow");
          setModal("EDIT_POST");
          setAnchorEl(null);
        }}
      >
        Edit Post
      </MenuItem>
    );
  }
  if (post?.isEdited) {
    menus.push(
      <MenuItem
        disableRipple
        dense
        onClick={() => {
          document.body.style.removeProperty("overflow");
          handleOpenEditPostHistoryModal();
          setAnchorEl(null);
        }}
      >
        View Edit History
      </MenuItem>
    );
  }
  if (isMe) {
    menus.push(
      <MenuItem onClick={handleDeletePost} disableRipple dense>
        Delete post
      </MenuItem>
    );
  }

  const clubEngage = permissions?.includes(
    ClubPermissionsBasedOnMemberType.CanEngage
  );

  return (
    <>
      <PostHeaderActionContainer>
        {!isMe &&
          platformPermission?.getFeatureToPlatformPermission?.includes(
            PlatformPermissionFromFeatureTypes.CanFollowPost
          ) && <FollowPost post={post} />}
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          aria-label="More Menu"
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <MoreMenu anchorEl={anchorEl} handleClose={handleClose}>
          {loading ? (
            <MenuItem sx={{ justifyContent: "center" }} disabled>
              <CircularProgress size={20} />
            </MenuItem>
          ) : (
            menus
          )}
        </MoreMenu>
      </PostHeaderActionContainer>
      <EditPostModal
        open={modal === "EDIT_POST" && isMe}
        post={post}
        handleClose={() => setModal(undefined)}
      />
    </>
  );
};

const PostHeaderActionContainer = styled.div`
  margin-left: auto;
`;
