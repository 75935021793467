import { Box } from "@mui/material";
import React, { FC, Suspense, lazy } from "react";
import SimpleBarReact from "simplebar-react";
import styled from "@emotion/styled";
import HighlightedAchievements from "components/HighlightedAchievements";

const ProductTour = lazy(() => import("./components/ProductTour"));
const UpcomingEvent = lazy(() => import("./components/UpcomingEvent"));
const TopTrendingStocks = lazy(() => import("./components/TopTrendingStocks"));
const TopTrendingSectors = lazy(
  () => import("./components/TopTrendingSectors")
);
const TopTrendingTopics = lazy(() => import("./components/TopTrendingTopics"));
const ReferEarn = lazy(() => import("./components/ReferEarn"));

const RightPanel: FC = () => (
  <StickyBox>
    <OnHoverScroll>
      <Suspense fallback={<div />}>
        <ProductTour />
      </Suspense>
      <Suspense fallback={<div />}>
        <ReferEarn />
      </Suspense>
      <HighlightedAchievements ga={{ event: "web_achievement_promotion" }} />
      <Suspense fallback={<div />}>
        <TopTrendingStocks />
      </Suspense>
      <Suspense fallback={<div />}>
        <TopTrendingSectors />
      </Suspense>
      <Suspense fallback={<div />}>
        <UpcomingEvent />
      </Suspense>
      <Suspense fallback={<div />}>
        <TopTrendingTopics />
      </Suspense>
    </OnHoverScroll>
  </StickyBox>
);

export default RightPanel;

const StickyBox = styled(Box)`
  flex: 1;
  height: calc(100vh - 64px);
  position: sticky;
  top: 64px;
`;

const OnHoverScroll = styled(SimpleBarReact)`
  max-height: calc(100vh - 64px);
  padding: 0 12px 8px 8px;
`;
