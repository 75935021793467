import React, { FC } from "react";
import { Avatar, Chip, ChipProps, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { Maybe, Comment } from "api";
import TimeAgo from "components/TimeAgo";

interface UserHeaderProps {
  comment: Maybe<Comment> | undefined;
  createdAt?: any;
  onClick?: () => void
}

const UserHeader: FC<UserHeaderProps> = ({ comment, createdAt, onClick }) => {
  const profileLink = `/profile/${encodeURI(`${comment?.User?.username}`)}`;
  return (
    <Container onClick={onClick}>
      <div>
        <CustomTypography variant="body2">
          <Link to={profileLink}>
            {comment?.User?.firstName} {comment?.User?.lastName}
          </Link>
          {Boolean(comment?.User?.isVerified) && <Image src={require("assets/image/blue-check.webp")} alt="Blue Tick" />}
          {Boolean(comment?.User?.greenTick) && <Image src={require("assets/image/green-check.webp")} alt="Green Tick" />}
          {Boolean(comment?.User?.tick3) && <Image src={require("assets/image/yellow-tick.webp")} alt="Yellow Tick" />}
          {comment?.isTopComment ? <TopComment label="Top Comment" /> : null}
        </CustomTypography>
        <Flex>
          <Link to={profileLink}>
            <Typography variant="caption">@{comment?.User?.username}</Typography>
          </Link>
          {createdAt && <TimeAgo time={createdAt} />}
        </Flex>
      </div>
    </Container>
  );
};

export default UserHeader;

const TopComment = styled((props: ChipProps) => (
  <Chip size="small" {...props} />
))({
  borderRadius: 4,
  fontSize: '0.625rem',
  marginLeft: 8,
  height: '20px'
})

const Flex = styled("div")`
  display: flex;
  .MuiTypography-root {
    font-size: 0.75rem !important;
    font-weight: 300 !important;
  }
`;

const Container = styled("div")`
  display: flex;

  a {
    display: flex;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .MuiAvatar-root {
    width: 36px;
    height: 36px;
    margin-right: 8px;
  }

  .MuiTypography-h6 {
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.25rem;
  }

  .MuiTypography-caption {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: bold;
    margin-right: 4px;
    color: #5c7082 !important;
  }
`;
const CustomTypography = styled(Typography)`
    line-height: 1rem;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    a {
        text-decoration: none;
    }
    a, a:hover {
        color: inherit;
    }
    a:hover {
        text-decoration: underline;
    }
`;

const Image = styled("img")`
    height: 14px;
    width: 14px;
    margin-left: 4px;
`;