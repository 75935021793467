import React, { useState, FC, lazy, Suspense, Fragment, useRef, memo } from "react";
import { IconButton, Tooltip, ClickAwayListener } from "@mui/material";
import styled from "@emotion/styled";
import { SmileIcon } from "icons";
import { useTheme } from "@emotion/react";
import { isBrowser } from "react-device-detect";
import { COLORS } from "assets/colors";

import Picker from "@emoji-mart/react";

const EmojiPicker: FC<any> = (props) => {
  const theme: any = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  const togglePicker = () => {
    setOpen((x) => !x);
  };

  const getEmojiData = async () => {
    const response = await fetch(
      "https://cdn.jsdelivr.net/npm/@emoji-mart/data"
    );
    return response.json();
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  if (isBrowser)
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Relative>
          <Suspense fallback={<Fragment />}>
            {open ? (
              <Picker
                theme={theme.palette.mode}
                data={getEmojiData}
                noCountryFlags
                set="native"
                previewPosition="none"
                searchPosition="none"
                emojiSize={20}
                emojiButtonSize={30}
                maxFrequentRows={0}
                perLine={8}
                autoFocus
                style={{ display: open ? "block" : "none" }}
                {...props}
              />
            ) : null}
          </Suspense>
          <StyledTooltip title="emoji" placement="top" arrow>
            <IconButton
              sx={{ color: COLORS.buttonActive }}
              component="label"
              color="primary"
              onClick={togglePicker}
            >
              <SmileIcon />
            </IconButton>
          </StyledTooltip>
        </Relative>
      </ClickAwayListener>
    );
  return null;
};

export default memo(EmojiPicker);

const Relative = styled.div`
  position: relative;
  background: "red";
`;

const StyledTooltip = styled(Tooltip)`
  .MuiTooltip-tooltipPlacementTop {
    margin-bottom: 0;
    margin-top: 24px;
  }
`;
