import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import { Maybe, Post } from "api";
import styled from "@emotion/styled";
import PostHeader from "./PostHeader";
import MediaPostNew from "./MediaPostNew";
import TextPost from "components/TextPost";
import { PollComponent } from "./PollComponent";
import { isEmpty } from "lodash-es";
import ImagePost from "./ImagePost";
import PostUnavailable from "./PostUnavailable";

interface SharePostComponentProps {
  post: Maybe<Post> | undefined;
  disabled?: boolean;
}

const SharePostComponent: FC<SharePostComponentProps> = ({ post, disabled }) => {
  const [show, setShow] = useState(!Boolean(post?.isSuspected));
  if (!post) return <PostUnavailable />

  return (
    <CardContent sx={disabled ? { pointerEvents: "none" } : {}}>
      <Link to={`/posts/${post?.postId}`} style={{ textDecoration: "none" }}>
        <PointerEventNone>
          <CustomCard variant="outlined">
            <PostHeader post={post} />
            {show ? (
              <>
                {!isEmpty(post?.textV2) && <CardContent>
                  <TextPost text={post?.textV2} />
                </CardContent>}
                <ImagePost media={post?.media} to={`/posts/${post?.postId}`} />
                <MediaPostNew post={post} isPostDetails={false} />
                {(post?.hasPoll && post?.getPoll) && <PollComponent post={post} />}
              </>
            ) : (
              <OffensiveContent>
                <p>This post may contain potentially offensive content.</p>
                <a onClick={() => setShow(true)}>View Post</a>
              </OffensiveContent>
            )}
          </CustomCard>
        </PointerEventNone>
      </Link>
    </CardContent>
  );
};

export default SharePostComponent;

const OffensiveContent = styled.div`
  line-height: 22px;
  color: #5c7082;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 15px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  a,
  p {
    display: inline;
    text-align: center;
  }
  p {
    color: #536471;
    margin-right: 5px;
  }
  background-color: #eff1fe;
  a {
    color: #2196f3;
    cursor: pointer;
    white-space: nowrap;
    margin-left: 5px;
  }
`;

const CustomCard = styled(Card)`
    margin-top: 16px;
    &:last-child {
        margin-bottom: 16px;
    }
    .MuiCardActions-root {
        .MuiButton-root {
            flex: 0 1 100%;
        }
    }
`;

const PointerEventNone = styled.div`
  pointer-events: none;
`;

const Unavailable = styled.div`
  padding: 15px 10px;
  border: 1px solid #efefef;
  border-radius: 6px;
  margin-bottom: 10px;
  h3 {
    font-size: 18px;
    color: #2c2c2c;
  }
  p {
    line-height: 24px;
    font-size: 14px;
    color: #787878;
  }
`;