import React, { FC } from "react";
import { Button, ButtonProps, styled } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { Link, LinkProps } from "react-router-dom";
import { isDarkMode } from "utills";

export const ViewOldPostLinkButton: FC<ButtonProps & LinkProps<any>> = (
  props
) => (
  <LinkButton
    variant="outlined"
    color="info"
    fullWidth
    disableElevation
    component={Link}
    {...props}
  >
    <HistoryIcon sx={{ color: "inherit" }} />
    View older posts
  </LinkButton>
);

const LinkButton = styled(Button)<ButtonProps & LinkProps<any>>(({ theme }) => ({
  marginTop: 15,
  marginBottom: 15,
  height: 44,
  backgroundColor: isDarkMode(theme) ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)",
  color: isDarkMode(theme) ? "rgb(241, 242, 243)" : "rgba(0, 0, 0, 0.87)",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  svg: {
    marginRight: 4
  }
}));
