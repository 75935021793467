import "simplebar-react/dist/simplebar.min.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "./slider.css";
import React, { FC, useEffect } from "react";
import ReactGA from "react-ga4";
import { install } from "resize-observer";
import App from "./App";
import ApolloProvider from "config/apollo";
import { AuthProvider } from "context/AuthContext";
import { CreatePostProvider } from "context/CreatePostContext";
import { BrowserRouter } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { ReportModalProvider } from "components/ReportModal";
import { SubscriptionModalProvider } from "components/SubscriptionModal";
import GlobalStyles from "./GlobalStyles";
import Theme from "./Theme";
import OIDCProvider from "providers/OIDC";
import ReferProvider from "components/refer";
import { UpgradePlanProvider } from "context/UpgradePlan/UpgradePlanContext";
import { GA_MEASUREMENT_ID } from "config";
import CustomToster from "components/CustomToster";
import ScrollToTop from "components/ScrollToTop";
import HashLinkObserver from "components/HashLinkObserver";
import { useIsServerUnderMaintainance } from "hooks";
import FixedLoader from "components/FixedLoader";
import { HelmetProvider } from "react-helmet-async";
import ServerMaintenance from "components/ServerMaintenance";

import { createRoot } from "react-dom/client";

ReactGA.initialize(GA_MEASUREMENT_ID);

const Index: FC = () => {
  useEffect(() => {
    const target = document.querySelector("title");

    const observer = new MutationObserver(function (mutations) {
      if (location.pathname.includes("/profile/")) {
        ReactGA.set({
          page_title: "Profile | StockEdge Social",
        });
      } else {
        ReactGA.set({
          page_title: (mutations[0].target as any).innerText.replace(
            /^\(\d+\)\s*/,
            ""
          ),
        });
      }
    });

    if (target) {
      observer.observe(target, {
        subtree: true,
        characterData: true,
        childList: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <HashLinkObserver />
        <ScrollToTop>
          <OIDCProvider>
            <Theme>
              <ConfirmProvider>
                <ApolloProvider>
                  <AuthProvider>
                    <GlobalStyles />
                    <ReferProvider>
                      <ReportModalProvider>
                        <CreatePostProvider>
                          <SubscriptionModalProvider>
                            <UpgradePlanProvider>
                              <App />
                            </UpgradePlanProvider>
                          </SubscriptionModalProvider>
                        </CreatePostProvider>
                      </ReportModalProvider>
                    </ReferProvider>
                  </AuthProvider>
                </ApolloProvider>
                <CustomToster />
              </ConfirmProvider>
            </Theme>
          </OIDCProvider>
        </ScrollToTop>
      </BrowserRouter>
    </HelmetProvider>
  );
};

const MainIndex = () => {
  const { loading, isServerDown, serverDownUpto } =
    useIsServerUnderMaintainance();
  if (loading)
    return (
      <Theme>
        <FixedLoader />
      </Theme>
    );
  if (isServerDown)
    return <ServerMaintenance serverDownUpto={serverDownUpto} />;
  return <Index />;
};

if (
  "serviceWorker" in navigator &&
  !location?.pathname?.includes("/locallogout")
) {
  navigator.serviceWorker.register(
    `${window.location.origin}/firebase-messaging-sw.js`,
    {
      scope: "/",
    }
  );
}

if (!window.ResizeObserver && !location.pathname.includes("/locallogout")) {
  install();
}

const container = document.getElementById("output");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<MainIndex />);
