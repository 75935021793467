import React, { FC, useRef } from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { Maybe, Post } from "api";
import EditHistory from "./EditHistory";

interface EditPostHistoryModalProps {
  open: boolean;
  post: Maybe<Post> | undefined;
  handleClose: () => void;
}

const EditPostHistoryModal: FC<EditPostHistoryModalProps> = ({ open, post, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <EditHistory
        post={post}
        handleCloseModal={handleClose}
      />
    </Modal>
  );
};

export default EditPostHistoryModal;

const Modal = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .MuiPaper-root {
    overflow-y: initial;
  }
  .MuiDialog-container {
    align-items: flex-start;
  }
  .MuiDialog-paper {
    width: 100%;
    max-width: 500px;
    min-height: 360px;
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
  .MuiDialogContent-root {
    padding: 12px 12px 0 12px;
    --line-height: 18px;
    --font-size: 14px;

    textarea {
      border: 0;
      outline: 0;
    }

    textarea.createComment__input {
      padding: 0 !important;
      margin: 0 !important;
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
    }

    div.createComment__highlighter {
      padding: 0 !important;
      margin: 0 !important;
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
    }

    .createComment__suggestions {
      min-width: 120px !important;
    }

    .createComment__suggestions__list {
      max-height: 240px;
      overflow-y: auto;
      background: #fff;
      box-shadow: 0 4px 13px -3px rgb(0 0 0 / 10%);
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);

      &::-webkit-scrollbar {
        width: 12px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: #ffffff; /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid #ffffffff; /* creates padding around scroll thumb */
      }
    }

    .createComment__suggestions__item {
      padding: 2px 4px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      &:last-child {
        border: 0;
      }
    }
    .createComment__suggestions__item--focused {
      background-color: var(--hover-text-color);
      color: #fff;
    }
    .createComment_user_mention {
      background-color: var(--hover-text-color);
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
      color: #fff;
      z-index: 1;
      position: relative;
      border-radius: 2px;
    }
    .createComment_hastag_mention {
      background-color: var(--hover-text-color);
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
      color: #fff;
      z-index: 1;
      position: relative;
      border-radius: 2px;
    }
    .createComment_stock_mention {
      background-color: var(--hover-text-color);
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
      color: #fff;
      z-index: 1;
      position: relative;
      border-radius: 2px;
    }
  }
  .MuiDialogTitle-root {
    .MuiTypography-root {
      font-size: 18px;
      font-weight: 700;
      color: var(--text-color-secondary);
    }
    .back-btn {
      display: none;
    }
  }
  .MuiDialogActions-root {
    justify-content: flex-start;
    align-items: center;
    padding: 8px 8px 8px 8px;
    .MuiButton-root {
      margin-left: auto;
    }
    /* .MuiButton-contained.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
    } */
    .MuiIconButton-root {
      padding: 8px;
      margin: 0;
      svg {
        height: 20px;
        width: 20px;
      }
      /* &.Mui-disabled {
        svg {
          stroke: rgb(0 0 0 / 25%);
        }
      } */
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      align-items: center;
    }
    .MuiDialogTitle-root {
      padding: 8px;
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 16px;
    }
    .back-btn {
      display: block;
      margin-right: 8px;
    }
    .close-btn {
      display: none;
    }
  }
  @media (min-width: 481px) {
    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
