import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { useHistory, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import FixedLoader from "components/FixedLoader";
import Routes from "./routes";
import { useAuthContext } from "context/AuthContext";
import { RequestACallProvider } from "components/RequestACall";
import { accountSync } from "layouts/MainLayout/accountSync";
import { useConfirm } from "material-ui-confirm";

const allowLogin = () => {
  const url = window.location.href;
  if (url.includes("/auth/callback")) return false;
  if (url.includes("/auth/complete-profile")) return false;
  if (url.includes("/download")) return false;
  if (url.includes("/terms")) return false;
  if (url.includes("/privacy")) return false;
  if (url.includes("/migrations")) return false;
  if (url.includes("/locallogout")) return false;
  if (url.includes("/analyst")) return false;
  if (url.includes("/plans")) return false;
  return true;
};

function removeQueryParams(url: string, paramsToRemove: string[]): string {
  const urlParts = url.split("?");
  if (urlParts.length < 2) {
    // No query params in the URL
    return url;
  }

  const baseUrl = urlParts[0];
  const queryParams = urlParts[1].split("&");
  const updatedParams = queryParams.filter((param) => {
    const paramName = param.split("=")[0];
    return !paramsToRemove.includes(paramName);
  });

  if (updatedParams.length === 0) {
    // If there are no query params left, return the base URL
    return baseUrl;
  }

  const updatedUrl = baseUrl + "?" + updatedParams.join("&");
  return updatedUrl;
}

let isDone = false;
const App: FC = () => {
  const {
    isAuthenticated,
    isLoading,
    activeNavigator,
    error,
    signinRedirect,
    user,
    signinSilent,
    events,
    removeUser,
    revokeTokens,
    clearStaleState,
    signoutSilent,
  } = useAuth();
  const confirm = useConfirm();
  const [loading, setLoading] = useState(false);
  const { removeUserInfo } = useAuthContext();
  const history = useHistory();
  const { search } = useLocation();

  /**
   * Check Session & Logout
   * https://brockallen.com/2016/08/12/check-session-support-in-oidc-client-js
   * Called on OP Signed Out
   */
  const handleAutoLogout = () => {
    removeUserInfo();
    signoutSilent();
    history.replace("/");
  };

  // Automatically sign-in
  useEffect(() => {
    // Remove OLD Refresh Token
    localStorage.removeItem("refreshToken");
    if (error?.message?.includes("login_required")) return;

    if (
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading
    ) {
      if (allowLogin()) {
        isDone = true;
        signinRedirect({ prompt: "none" });
      }
    }
  }, [
    isAuthenticated,
    activeNavigator,
    isLoading,
    signinRedirect,
    error?.message,
  ]);

  useEffect(() => {
    if (error?.message?.includes("invalid_grant")) {
      confirm({
        title: "Your session has expired!",
        description: "Sign in to continue",
        confirmationText: "Sign in",
        cancellationText: "Logout",
        confirmationButtonProps: {
          variant: "contained",
          disableElevation: true,
          autoFocus: true,
          size: "small",
        },
        cancellationButtonProps: {
          color: "error",
          size: "small",
          disableElevation: true,
        },
        allowClose: true,
        dialogProps: {
          PaperProps: {
            sx: {
              maxWidth: 400,
            },
          },
        }
      })
        .then(() => {
          signinRedirect();
        })
        .catch(() => {
          handleAutoLogout();
        });
      return;
    }
  }, [error?.message, confirm]);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  // useEffect(() => {
  //   // make renew manually
  //   return events.addAccessTokenExpiring(() => {
  //     signinSilent().catch(e => console.log("addAccessTokenExpiring", e));
  //   });
  // }, [events, signinSilent]);

  // useEffect(() => {
  //   console.log({ isDone, isAuthenticated });
  //   if (isAuthenticated && !isDone) {
  //     const url = window.location.href;
  //     if (!(url.includes("code="))) {
  //       isDone = true;
  //       setLoading(true);
  //       console.log("Silent Login Called!");
  //       signinSilent().finally(() => {
  //         setLoading(false);
  //       })
  //     }
  //   }
  // }, [isAuthenticated, isDone, events])

  const handleRefersh = useCallback(async () => {
    try {
      setLoading(true);
      const user = await signinSilent();
      if (user?.access_token) {
        const { data } = await accountSync(user?.access_token);
        if (data?.data?.accountSync_V2?.userDetails?.canBeOnboarded) {
          history.replace(`/onboarding`);
        } else {
          const paramsToRemove = ["OrderCode", "TransactionCode"];
          (window as Window).location = removeQueryParams(
            window.location.href,
            paramsToRemove
          );
        }
      }
    } catch (e: any) {
      console.error(e.resposne);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (searchParams.has("OrderCode") && searchParams.has("TransactionCode")) {
      // alert("Hello")
      handleRefersh();
    }
  }, []);

  useEffect(() => {
    events.addUserSignedOut(handleAutoLogout);
    return () => {
      events.removeUserSignedOut(handleAutoLogout);
    };
  }, []);
  console.log({ isLoading, loading });
  if (isLoading || loading) return <FixedLoader />;
  return (
    <RequestACallProvider>
      <Routes />
    </RequestACallProvider>
  );
};

export default App;
