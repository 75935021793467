import React, { FC } from "react";
import styled from "@emotion/styled";
import { Card, CardActions, Stack } from "@mui/material";
import { Maybe, Comment, Post, TagType } from "api";
import CommentHeader from "./CommentHeader";
import Comments from "ui/Comments";
import { CommentBottomActions } from "./CommentBottomActions";
import ReplyComponent, {
  RepliesContainer,
} from "components/ReplyComponent/Reply";
import TextPost from "components/TextPost";
import ImageComment from "./ImageComment";
import { Link } from "react-router-dom";
import MediaComment from "./MediaComponent";
import Avatar from "components/Avatar";
import { getIsRingVisible } from "utills";
import { sendEvent } from "ga4";

const Body = styled("div")`
  padding: 8px 12px 8px 12px;
`;

interface CommentCommentProps {
  post: Maybe<Post> | undefined;
  comment: Maybe<Comment>;
}

const CommentComment: FC<CommentCommentProps> = ({ post, comment }) => {
  const onMentionedTagTypeClick = (tagType: TagType, paramsLower?: string) => {
    if (tagType === TagType?.UserTag) {
      sendEvent({
        event: "user_button_clicked",
        component_name: "comment_mention",
      });
    } else if (tagType === TagType?.HashTag) {
      sendEvent({
        event: "topic_button_clicked",
        component_name: "comment_mention",
      });
    } else if (
      tagType === TagType?.DollarTag &&
      paramsLower?.includes("stock/")
    ) {
      sendEvent({
        event: "stock_button_clicked",
        component_name: "comment_mention",
      });
    } else if (
      tagType === TagType?.DollarTag &&
      (paramsLower?.includes("sector/") || paramsLower?.includes("industry/"))
    ) {
      sendEvent({
        event: "sector_button_clicked",
        component_name: "comment_mention",
      });
    }
  };

  return (
    <Stack direction="row" spacing={1} sx={{ marginLeft: 1, marginBottom: 1 }}>
      <Link to={`/profile/${encodeURI(`${comment?.User?.username}`)}`}>
        <Avatar
          hasRing={getIsRingVisible(comment?.User?.membershipLevel)}
          src={comment?.User?.avatar?.LowResUri}
          alt="Avatar"
        />
      </Link>
      <Stack sx={{ flex: 1 }}>
        <Card variant="outlined" sx={{ marginBottom: 2 }}>
          <CommentHeader comment={comment} post={post!} />
          <Body>
            <TextPost
              text={comment?.textV2}
              onMentionedTagTypeClick={onMentionedTagTypeClick}
            />
            <MediaComment media={comment?.media} />
          </Body>
          <ImageComment media={comment?.media} to={`/posts/${post?.postId}`} />
          <CommentBottomActions post={post} comment={comment} />
        </Card>
        {comment?.replies?.slice(0, 1)?.map((reply) => (
          <ReplyComponent
            key={reply?.replyId}
            post={post}
            comment={comment}
            reply={reply}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default CommentComment;

export const CommentsContainer = styled.div`
  padding: 8px 8px 8px 16px;
`;
