import { Maybe, Media } from "api";
import React, { FC, SyntheticEvent, useState } from "react";
import ReactPlayer from "react-player/lazy";
import ImageZoom from "components/ImageZoom";
import DisplayAudioPlayer from "./DisplayAudioPlayer";
import { styled } from "@mui/material";

interface PostDetailsMediaProps {
  media: Array<Maybe<Media>>;
}

interface State {
  imagePreview: boolean;
  index: number;
}

const PostDetailsMedia: FC<PostDetailsMediaProps> = ({ media }) => {
  console.log({ media });
  const [{ imagePreview, index }, setState] = useState<State>({
    imagePreview: false,
    index: 0,
  });

  const handleSetImagePreview = (event: SyntheticEvent, index: number) => {
    event.preventDefault();
    setState({ imagePreview: true, index });
  };

  const handleResetImagePreview = () => {
    console.log("close");
    setState({ imagePreview: false, index: 0 });
  };
  console.log({ media });
  return (
    <Container>
      {media?.map((item, key) => {
        // if (item?.type === "image/*")
        //   return (
        //     <ImageContainer key={key}>
        //       <Image
        //         src={item.uri.HighResUri}
        //         alt="image"
        //         onClick={(e) => handleSetImagePreview(e, key)}
        //         title="click to zoom image"
        //       />
        //     </ImageContainer>
        //   );
        if (item?.type === "video/*")
          return (
            <VideoConatiner key={key}>
              <ReactPlayer
                width="100%"
                url={item.uri.showUri}
                controls={true}
              />
            </VideoConatiner>
          );
        if (new RegExp("audio").test(item?.type!))
          return <DisplayAudioPlayer key={item?.name} audio={item} />;
        if (item?.type === "application/*")
          return (
            <DocContainerLink
              key={key}
              href={`${item.uri.showUri}`}
              target="_blank"
            >
              <DocImage src={item.uri.ThumbnailUrl} alt="doc" />
              <Pdf src={require("assets/image/pdf.webp")} alt="pdf-thumbnail" />
            </DocContainerLink>
          );
        return null;
      })}
      {imagePreview && (
        <ImageZoom
          images={media}
          index={index}
          handleClose={handleResetImagePreview}
        />
      )}
    </Container>
  );
};

export default PostDetailsMedia;

const Container = styled("div")``;
const ImageContainer = styled("div")`
  margin: 10px auto;
`;
const VideoConatiner = styled("div")`
  margin: 10px auto;
  & > div {
    height: auto !important;
  }
`;
const DocContainerLink = styled("a")`
  display: block;
  margin: 10px auto;
  position: relative;
`;

const DocImage = styled("img")`
  height: auto;
  width: 100%;
  background-color: #fff;
`;

const Image = styled("img")`
  height: auto;
  width: 100%;
  cursor: pointer;
`;

const Pdf = styled("img")`
  height: 56px;
  width: auto;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;
