import React, { FC, createContext, useState, Fragment, useContext, PropsWithChildren } from "react";
import ReactPlayer from "react-player/lazy";
import { Dialog, DialogContent, IconButton, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useGetExternalResources } from "hooks";
import { ExternalResource } from "api";

interface ProductTourProps {
    VDO_URL: Pick<ExternalResource, "value">
    handleModalOpen?: () => void;
}

const ProductTourContext = createContext<ProductTourProps>({} as ProductTourProps);


export const ProductTourProvider: FC<PropsWithChildren> = (props) => {
    const isVisible = useMediaQuery('(min-width:960px)');
    const { externalResources } = useGetExternalResources();
    const [open, setOpen] = useState(false);

    const handleModalClose = () => setOpen(false);
    const handleModalOpen = () => setOpen(true);

    const VDO_URL = isVisible ? externalResources?.PRODUCT_TOUR_WEB : externalResources?.PRODUCT_TOUR_APP;
    return (
        <Fragment>
            <ProductTourContext.Provider value={{ VDO_URL, handleModalOpen }} {...props} />
            <Modal
                fullScreen
                open={open}
                onClose={handleModalClose}
                style={{ backgroundColor: 'transparent' }}
            >
                <DialogContent>
                    <IconButton onClick={handleModalClose}>
                        <Close htmlColor="#000" />
                    </IconButton>
                    {VDO_URL ? <ReactPlayer
                        controls
                        wrapper={VideoContainer}
                        url={VDO_URL as string}
                        playing
                    /> : null}
                </DialogContent>
            </Modal>
        </Fragment>
    )
}

const Modal = styled(Dialog)`
    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.75);
    }
    .MuiPaper-root {
        background-color: transparent;
    }
    .MuiDialogContent-root {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    button.MuiIconButton-root {
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 8px;
        background-color: #fff;
        z-index: 9999;
    }
`;

const VideoContainer = styled.div`
  width: auto !important;
  height: 100% !important;
  background-color: #000000;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 16px;
  max-width: 1024px;
  max-height: 768px;
`;

export const useProductTour = () => useContext(ProductTourContext);
