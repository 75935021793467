import { Button, Paper } from "@mui/material";
import React, { FC, useCallback } from "react";
import styled from "@emotion/styled";
import { useAuthContext } from "context/AuthContext";
import { useCreatePost } from "./CreatePostProvider";
import Avatar from "components/Avatar";
import { Maybe, Club, useMeQuery } from "api";
import { DEFAULT_AVATAR } from "config";
import { getIsRingVisible } from "utills";
import { sendEvent } from "ga4";

export const CreatePostButton: FC<{ club?: Maybe<Club> | undefined }> = ({ club }) => {
  const { user } = useAuthContext();
  const { handleOpenModal: handleOpenCreatePostModal } = useCreatePost();
  const { data } = useMeQuery();

  
  const handleOnClick = useCallback(() => {
    sendEvent({
      event: "create_post_clicked",
      component_name: club ? "club" : "home"
    })
    handleOpenCreatePostModal(club ?? null);
  }, [club]);

  let renderText = `Start a discussion @${user?.username}`;
  if (club) {
    renderText = `Start a discussion in ${club?.clubName}`;
  }

  return (
    <CustomPaper variant="outlined">
      <Avatar
        src={data?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
        alt="Avatar"
        hasRing={getIsRingVisible(data?.me?.membershipLevel)}
      />
      <Button fullWidth onClick={handleOnClick}>{renderText}</Button>
    </CustomPaper>
  );
}

const CustomPaper = styled(Paper)`
  padding: 20px 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  .MuiAvatar-root {
    margin-right: 8px;
  }
  .MuiButton-root {
    max-width: 100%;
    border-radius: 24px;
    justify-content: flex-start;
    padding-left: 16px;
    background-color: ${({ theme }: any) => theme.palette.action.selected};
    &:hover {
      background-color: ${({ theme }: any) => theme.palette.action.selected};
    }
  }
`;
