import React, { FC, Fragment, Suspense, lazy, useMemo } from "react";
import { ComponentType, Maybe, InterstitialsData } from "api";
import HighlightedAchievements from "components/HighlightedAchievements";

const ClubsInSpotLightSlider = lazy(() => import("./components/ClubsInSpotLight"));
const SectorSlider = lazy(() => import("./components/SectorSlider"));
const StockSlider = lazy(() => import("./components/StockSlider"));
const TopicSlider = lazy(() => import("./components/TopicSlider"));
const Promotional = lazy(() => import("./components/Promotional"));
const SuggestedPeople = lazy(() => import("./components/SuggestedPeople"));
interface SmartFeedProps {
    data: Maybe<InterstitialsData> | undefined;
}

const SmartFeed: FC<SmartFeedProps> = ({ data }) => {
    const renderComponent = useMemo(() => {
        switch (data?.componentType) {
            case ComponentType.ClubListForSmartFeed:
                return <ClubsInSpotLightSlider data={data} />
            case ComponentType.PeopleListForSmartFeed:
                return <SuggestedPeople data={data} />
            case ComponentType.PromotionsForSmartFeed:
                return <Promotional data={data} />
            case ComponentType.SectorListForSmartFeed:
                return <SectorSlider data={data} />
            case ComponentType.StockListForSmartFeed:
                return <StockSlider data={data} />
            case ComponentType.TopicListForSmartFeed:
                return <TopicSlider data={data} />
            case ComponentType.BadgePromotion:
                return <HighlightedAchievements ga={{event:"smart_feed_achievement_promotion"}}/>
            default:
                return null;
        }
    }, [data?.componentType])
    return (
        <Suspense fallback={<Fragment />}>
            {renderComponent}
        </Suspense>
    )
}

export default SmartFeed;