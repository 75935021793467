import React, { FC, useMemo } from "react";
import Activities from "./Activities";
import { CreatePostButton } from "components/CreatePost";
import HelmetWithNotificationCount from "components/HelmetWithNotificationCount";
import { PostsContainer } from "components/PostComponent";
import { HomeTourProvider } from "providers/GuidedTour/HomeTourProvider";
import { TourInit } from "pages/club/components/TourInit";
import { useGetUserFeedTypeListQuery } from "api";
import FeedSelection from "./FeedSelection";

export const reference = React.createRef<HTMLButtonElement>();

const Home: FC = () => {
  const { data } = useGetUserFeedTypeListQuery();

  const defaultFeed = useMemo(() => {
    return data?.getUserFeedTypeList?.find((item) => item?.default == true)
  }, [data?.getUserFeedTypeList]);

  return (
    <React.Fragment>
      <HomeTourProvider>
        <TourInit />
        <HelmetWithNotificationCount title="StockEdge Social - An exclusive premium social platform for stock market traders and investors by StockEdge" />
        <PostsContainer>
          <CreatePostButton />
          <FeedSelection
            data={data}
            feedTypeId={defaultFeed?.feedTypeId}
          />
          {defaultFeed?.feedType ? <Activities ref={reference} feedType={defaultFeed?.feedType} showSmartFeed={defaultFeed.showSmartFeed} /> : null}
        </PostsContainer>
      </HomeTourProvider>
    </React.Fragment>
  );
};

export default Home;
