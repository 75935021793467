import React, { FC, Fragment } from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Maybe, Comment, Post, useIsReportLazyQuery, useDeleteCommentMutation, PlatformPermissionFromFeatureTypes, useGetFeatureToPlatformPermissionQuery } from "api";
import UserHeader from "./UserHeader";
import { MenuLoader } from "components/MenuLoader";
import { useSubscriptionModal } from "components/SubscriptionModal";
import { useReportModal } from "components/ReportModal";
import { useAuthContext } from "context/AuthContext";
import { useConfirm } from "material-ui-confirm";
import toast from 'react-hot-toast';
import { gql } from "@apollo/client";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { usePermissionFeatureMapping } from "hooks";
import { sendEvent } from "ga4";

interface CommentHeaderProps {
    comment: Maybe<Comment>;
    post: Maybe<Post>;
}

const CommentHeader: FC<CommentHeaderProps> = ({ comment, post }) => {
    const confirm = useConfirm();
    const { platformFeatureMap, clubFeatureMap } = usePermissionFeatureMapping();
    const { handleOpenModal } = useSubscriptionModal();
    const { handleModalOpen } = useUpgradePlanContext();
    const reportModal = useReportModal();
    const { user } = useAuthContext();
    const [deleteComment] = useDeleteCommentMutation();
    const [getIsReport, { data, loading }] = useIsReportLazyQuery({
        fetchPolicy: "network-only"
    });
    const { data: platformPermission } = useGetFeatureToPlatformPermissionQuery();

    const handleDeleteComment = React.useCallback(() => {
        confirm({
            title: 'Are you sure to delete this comment?',
            confirmationText: "Delete",
            confirmationButtonProps: {
                style: {
                    backgroundColor: "red"
                }
            },
            dialogProps: {
                PaperProps: {
                    style: {
                        maxWidth: 400
                    }
                }
            }
        }).then(() => {
            const variables = { data: { postId: post?.postId + "", commentId: `${comment?.commentId}` } };
            deleteComment({
                variables,
                update: (cache, { data }) => {
                    cache.updateFragment(
                        {
                            id: cache.identify(post!),
                            fragment: gql`
                            fragment UpdatePostOnDeleteComment on Post {
                                commentcount
                            }
                          `
                        },
                        (d) => ({
                            ...d,
                            commentcount: d.commentcount - 1
                        })
                    )
                    cache.evict({ id: cache.identify(comment!) })
                    toast.success('Successfully deleted!');
                }
            })
        }).catch(() => { console.log("error") })
    }, [post?.postId, comment?.commentId, deleteComment]);


    const isMe = comment?.User?.id === user?.id;

    const onClick = () => {
        sendEvent({
            event: "user_button_clicked",
            component_name: "comment"
        })
    }
    return (
        <Container>
            <UserHeader comment={comment} createdAt={comment?.createdAt} onClick={onClick} />
            <Menu
                menuButton={
                    <IconButton
                        onClick={() =>
                            getIsReport({
                                variables: { postId: post?.postId, commentId: comment?.commentId },
                            })
                        }
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
                transition
                arrow
                offsetX={-150}
                offsetY={0}
            >
                {loading ? (
                    <MenuLoader />
                ) : (
                    <Fragment>
                        <MenuItem
                            onClick={() => {
                                if (!platformPermission?.getFeatureToPlatformPermission?.includes(PlatformPermissionFromFeatureTypes.CanReport)) {
                                    sendEvent({
                                        event: "upgrade_modal_initialised",
                                        component_name: "post_details",
                                        feature_name_list: platformFeatureMap[PlatformPermissionFromFeatureTypes.CanReport]
                                    })
                                    handleModalOpen();
                                    return;
                                }
                                reportModal?.handleOpenModal(
                                    "COMMENT",
                                    post?.postId + "",
                                    comment?.commentId!,
                                    undefined
                                );
                            }}
                            disabled={data?.getIsReported}
                        >
                            {data?.getIsReported
                                ? "Already reported"
                                : "Report this comment"}
                        </MenuItem>
                        {isMe && (
                            <MenuItem onClick={handleDeleteComment}>
                                Delete comment
                            </MenuItem>
                        )}
                    </Fragment>
                )}
            </Menu>
        </Container>
    )
}

export default CommentHeader;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px;

  .MuiIconButton-root {
    padding: 6px;
    margin-left: auto;
    .MuiSvgIcon-root {
      height: 18px;
      width: 18px;
    }
  }
`;