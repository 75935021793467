import React, { FC, Fragment } from "react";
import { IconButton, styled } from "@mui/material";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Maybe,
  Reply,
  Post,
  Comment,
  useIsReportLazyQuery,
  useDeleteReplyMutation,
  PlatformPermissionFromFeatureTypes,
  useGetFeatureToPlatformPermissionQuery,
} from "api";
import UserHeader from "./UserHeader";
import { useAuthContext } from "context/AuthContext";
import { useReportModal } from "components/ReportModal";
import { useConfirm } from "material-ui-confirm";
import { MenuLoader } from "components/MenuLoader";
import toast from "react-hot-toast";
import { gql } from "@apollo/client";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { usePermissionFeatureMapping } from "hooks";
import { sendEvent } from "ga4";
interface CommentHeaderProps {
  post: Maybe<Post> | undefined;
  comment: Maybe<Comment> | undefined;
  reply: Maybe<Reply>;
}

const CommentHeader: FC<CommentHeaderProps> = ({ post, comment, reply }) => {
  const { platformFeatureMap, clubFeatureMap } = usePermissionFeatureMapping();
  const confirm = useConfirm();
  const reportModal = useReportModal();
  const { handleModalOpen } = useUpgradePlanContext();
  const { user } = useAuthContext();
  const [deleteReply] = useDeleteReplyMutation();
  const [getIsReport, { data, loading }] = useIsReportLazyQuery({
    fetchPolicy: "network-only",
  });
  const { data: platformPermission } = useGetFeatureToPlatformPermissionQuery();

  const handleDeleteComment = React.useCallback(() => {
    confirm({
      title: "Are you sure to delete this reply?",
      confirmationText: "Delete",
      confirmationButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
      dialogProps: {
        PaperProps: {
          style: {
            maxWidth: 400,
          },
        },
      },
    })
      .then(() => {
        const variables = {
          data: {
            postId: post?.postId + "",
            commentId: `${comment?.commentId}`,
            replyId: reply?.replyId + "",
          },
        };
        deleteReply({
          variables,
          update: (cache, { data }) => {
            cache.updateFragment(
              {
                id: cache.identify(comment!),
                fragment: gql`
                  fragment UpdateCommentOnDeleteReply on Comment {
                    replycount
                  }
                `,
              },
              (d) => ({
                ...d,
                replycount: d.replycount - 1,
              })
            );
            cache.evict({ id: cache.identify(reply!) });
            toast.success("Successfully deleted!");
          },
        });
      })
      .catch(() => {
        console.log("error");
      });
  }, [post?.postId, comment?.commentId, deleteReply]);

  const isMe = reply?.User?.id === user?.id;

  const onClick = () => {
    sendEvent({
      event: "user_button_clicked",
      component_name: "reply",
    });
  };
  return (
    <Container onClick={onClick}>
      <UserHeader user={reply?.User} createdAt={reply?.createdAt} />
      <Menu
        menuButton={
          <IconButton
            onClick={() =>
              getIsReport({
                variables: {
                  postId: post?.postId,
                  commentId: comment?.commentId,
                  replyId: reply?.replyId,
                },
              })
            }
          >
            <MoreVertIcon />
          </IconButton>
        }
        transition
        arrow
        offsetX={-158}
        offsetY={0}
      >
        {loading ? (
          <MenuLoader />
        ) : (
          <Fragment>
            <MenuItem
              onClick={() => {
                if (
                  !platformPermission?.getFeatureToPlatformPermission?.includes(
                    PlatformPermissionFromFeatureTypes.CanReport
                  )
                ) {
                  sendEvent({
                    event: "upgrade_modal_initialised",
                    component_name: "post_details",
                    feature_name_list:
                      platformFeatureMap[
                        PlatformPermissionFromFeatureTypes.CanReport
                      ],
                  });
                  handleModalOpen();
                  return;
                }
                reportModal?.handleOpenModal(
                  "REPLY",
                  post?.postId!,
                  comment?.commentId!,
                  reply?.replyId!
                );
              }}
              disabled={data?.getIsReported}
            >
              {data?.getIsReported ? "Already reported" : "Report this reply"}
            </MenuItem>
            {isMe && (
              <MenuItem onClick={handleDeleteComment}>Delete reply</MenuItem>
            )}
          </Fragment>
        )}
      </Menu>
    </Container>
  );
};

export default CommentHeader;

const Container = styled("div")`
  display: flex;
  align-items: flex-start;
  padding: 8px;

  .MuiIconButton-root {
    padding: 6px;
    margin-left: auto;
    .MuiSvgIcon-root {
      height: 18px;
      width: 18px;
    }
  }
`;
