import React, { FC, Fragment, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import {
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  DialogActions,
} from "@mui/material";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ModalType } from "./PostCreator";

interface Props {
  image: File | undefined;
  editImgaeIndex: number | undefined;
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  setModal: React.Dispatch<React.SetStateAction<ModalType>>;
  setEditImageIndex: React.Dispatch<React.SetStateAction<number | undefined>>
}

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const ImageEditor: FC<Props> = ({ image, editImgaeIndex, setEditImageIndex, setImages, setModal }) => {
  console.log({ image, editImgaeIndex });
  const cropperRef = useRef<HTMLImageElement>(null);
  const handleSave = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
    const file = dataURLtoFile(
      cropper.getCroppedCanvas().toDataURL(),
      `${Date.now()}.png`
    );
    setImages(x => x.map((image, i) => {
      if (i === editImgaeIndex) return file;
      return image;
    }));
    setEditImageIndex(undefined);
    setModal("TEXT_POST");
  };
  const rotateClock = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.rotate(90);
  };
  const rotateAntiClock = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.rotate(-90);
  };
  return (
    <Fragment>
      <DialogTitle>
        <IconButton onClick={() => setModal("TEXT_POST")}>
          <ArrowBackIcon />
        </IconButton>
        <Typography style={{ width: "100%" }}>Edit Image</Typography>
      </DialogTitle>
      <DialogContent style={{ overflowX: "hidden" }}>
        {image && (
          <Cropper
            src={URL.createObjectURL(image)}
            style={{ height: 400, width: "100%" }}
            initialAspectRatio={16 / 9}
            guides={false}
            // crop={onCrop}
            ref={cropperRef}
            zoomTo={0.3}
            // initialAspectRatio={1}
            viewMode={1}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
          />
        )}
      </DialogContent>
      <DialogActions>
        <StyledTooltip title="Rotate Left" placement="top" arrow>
          <IconButton onClick={rotateAntiClock}>
            <RotateLeftIcon />
          </IconButton>
        </StyledTooltip>
        <StyledTooltip title="Rotate Right" placement="top" arrow>
          <IconButton onClick={rotateClock}>
            <RotateRightIcon />
          </IconButton>
        </StyledTooltip>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Fragment>
  );
};

const StyledTooltip = styled(Tooltip)`
  .MuiTooltip-tooltipPlacementTop {
    margin-bottom: 0;
    margin-top: 24px;
  }
`;
