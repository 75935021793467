import React, { FC, Fragment, useState } from "react";
import styled from "@emotion/styled";
import { Maybe, Media } from "api";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import HeaderLogo from "components/HeaderLogo";
import { Link } from "react-router-dom";
import { Download } from "@mui/icons-material";

interface ImagePostProps {
    media?: Maybe<Maybe<Media>[]> | undefined;
}

const ImagePost: FC<ImagePostProps> = ({ media }) => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const images: Array<string> = media?.filter(x => x?.type.includes('image/'))?.map(x => x?.uri?.HighResUri) || [];

    const handleDownloadImage = async (imageSrc: string) => {
        const image = await fetch(imageSrc, { mode: "cors" })
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)

        const link = document.createElement('a')
        link.href = imageURL
        link.download = Date.now().toString();
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <Fragment>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[photoIndex + 1]}
                    prevSrc={images[photoIndex - 1]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(x => x - 1)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex(x => x + 1)
                    }
                    closeLabel="Press Esc to close"
                    imageTitle={<HeaderLogo component={Link} to="/" sx={{
                        ".MuiTypography-root": {
                            color: "#fff"
                        }
                    }} />}
                    toolbarButtons={[
                        <button
                            key="1"
                            onClick={() => handleDownloadImage(images[photoIndex])}
                            type="button"
                            aria-label="Download"
                            title="Download"
                            className="ril-zoom-in ril__toolbarItemChild ril__builtinButton"
                            style={{ background: "transparent", color: "#fff" }}
                        >
                            <Download />
                        </button>
                    ]}
                />
            )}
            {images?.map((item, key) => (
                <ImageContainer
                    key={key}
                    onClick={(e) => {
                        setPhotoIndex(key);
                        setIsOpen(true);
                    }}>
                    <Image
                        src={item}
                        alt="image"

                        title="click to zoom image"
                    />
                </ImageContainer>
            ))}
        </Fragment>
    );
};

export default ImagePost;

const ImageContainer = styled.div`
  margin: 10px auto;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

