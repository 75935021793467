import React, { FC, Fragment, PropsWithChildren } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import FixedLoader from "components/FixedLoader";
import { useAuthContext } from "context/AuthContext";

const SigninRoute: FC<PropsWithChildren<RouteProps>> = ({
  children,
  ...rest
}) => {
  const { isAuthenticated, isLoading } = useAuth();
  const { isAuth } = useAuthContext();
  if (isLoading) return <FixedLoader />;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return !(isAuthenticated && isAuth) ? (
          <Fragment>{children}</Fragment>
        ) : (
          <Redirect
            to={{
              pathname: (location?.state as any)?.pathname || "/home",
              state: { pathname: location.pathname },
            }}
          />
        );
      }}
    />
  );
};

export default SigninRoute;
